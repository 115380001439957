import type { MutableRefObject } from 'react';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import { getVideoThumbnailUrl } from '../../occasions/OccasionVideoPreviewHelper';
import { setExternalVideoConsentCookie } from './CookieHelper';

/**
 * Function to get the preview video element with thumbnail image and cookie consent banner
 */
export function getVideoConsentBannerElement(
  cx: ClassNamesFnWrapper,
  videoElement: HTMLElement,
  iframeSourceUrl: string,
  bannerText: string,
  buttonText: string
): HTMLElement {
  const textClass = cx('mb-0');
  const containerClasses = videoElement.classList;
  const url = videoElement.dataset.videoRemoteVid;
  const thumbnailUrl = getVideoThumbnailUrl(url, iframeSourceUrl);
  const bannerElement = document.createElement('div');
  bannerElement.dataset.videoId = videoElement.dataset.videoId;
  bannerElement.className = cx(`lia-cookie-banner-container ${containerClasses}`);
  const bannerClass = cx('lia-cookie-banner', {
    'lia-cookie-banner-small': containerClasses.contains('lia-media-size-small')
  });
  const buttonClass = cx('lia-cookie-consent-button', {
    'lia-g-mt-5': containerClasses.contains('lia-media-size-small')
  });
  bannerElement.innerHTML = `<img src="${thumbnailUrl}" alt="" />
        <div class="${bannerClass}">
           <p class="${textClass}">${bannerText}</p>
           <button class="${buttonClass}">${buttonText}</button>
        </div>`;
  return bannerElement;
}

/**
 * Function to add onClick functionality to the cookie consent button
 */
export function addConsentButtonClickAction(
  reference: MutableRefObject<HTMLElement>,
  cx: ClassNamesFnWrapper,
  videoElementsMap: Map<string, HTMLElement>
): void {
  const bannerElements = reference.current?.querySelectorAll(
    `.${cx('lia-cookie-banner-container')}`
  );
  const buttonElements = reference.current?.querySelectorAll(`.${cx('lia-cookie-consent-button')}`);
  buttonElements?.forEach(button => {
    button.addEventListener('click', () => {
      setExternalVideoConsentCookie();
      bannerElements?.forEach(bannerItem => {
        videoElementsMap.forEach(videoItem => {
          if ((bannerItem as HTMLDivElement)?.dataset.videoId === videoItem.dataset.videoId) {
            bannerItem.replaceWith(videoItem);
          }
        });
      });
    });
  });
}
