import type { TextVariantsContextInterface } from '@aurora/shared-client/components/context/TextVariantContext';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import { useI18n } from '@aurora/shared-client/components/useI18n';
import { getModulePathForCustomComponent } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { I18n } from '@aurora/shared-types/texts';
import { useContext } from 'react';
import { getModulePathForComponent } from '../features/endUserModuleRegistry';

/**
 * Load translations for custom component
 * @param texts records of keys/vales
 * @param ignoreProvisionedText
 * @param textVariantContextOverride Override the text variant context
 */
export default function useCustomComponentTranslation<T, R>(
  customComponentId: string,
  texts: Record<string, string>,
  ignoreProvisionedText?: boolean,
  textVariantContextOverride?: TextVariantsContextInterface
): Omit<I18n<T, R>, 'loading' | 'refetch'> {
  const { instanceId } = useContext(WidgetContext);
  const namespace = getModulePathForCustomComponent(customComponentId);

  const i18n = useI18n(
    namespace,
    texts,
    instanceId,
    ignoreProvisionedText,
    (id: EndUserComponent) => getModulePathForComponent(id),
    textVariantContextOverride,
    customComponentId
  );

  return {
    ...i18n
  };
}
