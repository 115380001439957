import type { QueryResult } from '@apollo/client';
import React, { useId } from 'react';
import { SharedComponent } from '../../../enums';
import { LoadingSize } from '../../../types/enums';
import useTranslation from '../../useTranslation';
import ApolloErrorAlert from '../ApolloErrorAlert/ApolloErrorAlert';
import { LoadingVariant } from '../Loading/enums';
import Loading from '../Loading/Loading';
import type { LoadingVariantTypeAndProps } from '../Loading/types';
import SystemAlert from '../SystemAlert/SystemAlert';

interface Props<TData, TVariables> {
  /**
   * The query result from a GraphQL call.
   */
  queryResult: QueryResult<TData, TVariables>;
  /**
   * Whether valid data is required to be returned from the query before rendering.
   */
  required?: boolean;
  /**
   * A message to display in case the query returns no data.
   */
  requiredMessage?: string;
  /**
   * A callback function called only when the data is available from the query.
   *
   * @callback
   */
  children(): React.ReactNode;
  /**
   * The size of the loading indicator.
   */
  loadingSize?: LoadingSize;
  /**
   * Force the alert to be shown, even in production
   */
  forceShowErrors?: boolean;
}

/**
 * Adds support for handling the loading state, error state, and display of a
 * child component once data has successfully loaded from a GraphQL query.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const QueryHandler = <TData, TVariables>({
  queryResult,
  children = (): null => null,
  required = false,
  requiredMessage,
  loadingSize = LoadingSize.LG,
  forceShowErrors = false
}: Props<TData, TVariables>): React.ReactElement => {
  const uid = useId();
  const { loading, error, data } = queryResult;
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.QUERY_HANDLER);
  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: loadingSize
    }
  };

  if (textLoading) {
    return null;
  }

  if (!data && loading) {
    return <Loading variant={loadingVariant} />;
  }

  if (error != null) {
    return <ApolloErrorAlert error={error} forceShow={forceShowErrors} />;
  }

  if (!data) {
    // This can be null if the component is dynamically loaded using `dynamic/next`.
    return null;
  }

  if (required && Object.values(data).filter(i => i !== null).length === 0) {
    return <SystemAlert id={uid} title={formatMessage('title')} message={requiredMessage} />;
  }

  return <>{children()}</>;
};

export default QueryHandler;
